import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Clock, Heart, Flag, CirclePlus, Minus, Plus } from 'lucide-react';
import axios from 'axios';
import './PullCard.css';
import { AuthContext } from '../App';  // Asegúrate de que la ruta sea correcta

const PullCard = ({ pull, onOrderChange }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const [isInCart, setIsInCart] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [showControls, setShowControls] = useState(false);

  const checkOrderStatus = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/current-cart/`);
      if (response.data) {
        const line = response.data.salesorderline_set.find(line => line.product === pull.product.id_product);
        if (line) {
          setIsInCart(true);
          setQuantity(line.product_qty);
          setShowControls(true);
        } else {
          setIsInCart(false);
          setQuantity(0);
          setShowControls(false);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log('No hay carrito activo');
        setIsInCart(false);
        setQuantity(0);
        setShowControls(false);
      } else {
        console.error('Error al verificar el estado de la orden:', error);
      }
    }
  }, [pull.product.id_product]);

  useEffect(() => {
    if (isAuthenticated) {
      checkOrderStatus();
    }
  }, [isAuthenticated, checkOrderStatus]);

  const toggleCart = () => {
    setShowControls(!showControls);
    if (!isInCart) {
      updateCart(1);
    }
  };

  const updateCart = async (newQuantity) => {
    try {
      let order;
      
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/current-cart/`);
        order = response.data;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/user/`);
          const customerId = userResponse.data.id;

          const newOrderResponse = await axios.post(`${process.env.REACT_APP_API_URL}/sales-orders/`, {
            customer: customerId,
            state: 'draft',
            order_type: 'regular',
            salesorderline_set: []
          });
          order = newOrderResponse.data;
        } else {
          throw error;
        }
      }

      let updatedLines = order.salesorderline_set.filter(line => line.product !== pull.product.id_product);
      if (newQuantity > 0) {
        updatedLines.push({
          product: pull.product.id_product,
          product_qty: newQuantity,
          price_unit: parseFloat(pull.product.price)
        });
      }

      await axios.put(`${process.env.REACT_APP_API_URL}/sales-orders/${order.id_salesorder}/`, {
        ...order,
        salesorderline_set: updatedLines
      });

      setQuantity(newQuantity);
      setIsInCart(newQuantity > 0);
      setShowControls(newQuantity > 0);
      if (onOrderChange) {
        onOrderChange();
      }
    } catch (error) {
      console.error('Error al actualizar el carrito:', error);
    }
  };

  return (
    <div className="pull-card">
      <div className="card-image">
        <div className="aspect-w-9 aspect-h-9">
          <img src={pull.product.img} alt={pull.product.name} className="max-w-full max-h-full object-contain rounded" />
        </div>
      </div>
      
      <div className="card-content">
        <h2 className="product-name">{pull.product.name}</h2>
        
        <div className="price-container">
          <div className="current-price">S/{pull.product.price}</div>
          <div className="original-price">S/{pull.product.price}</div>
        </div>
        
        <div className="cart-controls">
          <button className={`add-to-cart ${isInCart ? 'in-cart' : ''}`} onClick={toggleCart}>
            <CirclePlus />
            {isInCart ? 'Listo!' : 'Lo quiero!'}
          </button>
          
          {showControls && (
            <div className="quantity-controls">
              <button className="quantity-btn" onClick={() => updateCart(Math.max(0, quantity - 1))}>
                <Minus size={16} />
              </button>
              <span className="quantity">{quantity}</span>
              <button className="quantity-btn" onClick={() => updateCart(quantity + 1)}>
                <Plus size={16} />
              </button>
            </div>
          )}
        </div>
        
        <div className="progress-container">
          <div className="progress-stats">
            <span><Heart size={16} /> {pull.current}/{pull.goal}</span>
            <span><Clock size={16} /> {pull.time_left}</span>
            <span><Flag size={16} /> 10% off</span>
          </div>
          <div className="progress-bar">
            <div
              className="progress-fill"
              style={{ width: `${Math.min((pull.current / pull.goal) * 100, 100)}%` }}
            ></div>
          </div>
        </div>
        
        <p className="discount-info">Si llegamos a la meta, ¡obtendrás un 10% de descuento!</p>
      </div>
    </div>
  );
};

export default PullCard;