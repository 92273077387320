import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';
import Layout from './components/Layout';
import PullsList from './components/PullsList';
import CarritoCompra from './components/CarritoCompra';
import MisPedidos from './components/MisPedidos';
import './global.css';

import RegistroUsuario from './components/RegistroUsuario';
import LoginUsuario from './components/LoginUsuario';
import DireccionEnvio from './components/DireccionEnvio';
import setupInterceptors from './utils/axiosInterceptor';

export const AuthContext = React.createContext(null);

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = React.useContext(AuthContext);
  
  if (isLoading) {
    return <div>Cargando...</div>;
  }
  
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  
  return children;
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkAuthStatus = useCallback(async () => {
    const token = localStorage.getItem('accessToken');
    console.log('Token present:', !!token);
    if (token) {
      try {
        await axios.get(`${process.env.REACT_APP_API_URL}/user/`);
        setIsAuthenticated(true);
      } catch (error) {
        console.error('Error verificando token:', error);
        logout();
      }
    } else {
      setIsAuthenticated(false);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setupInterceptors(logout);
    checkAuthStatus();
    
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  }, [checkAuthStatus]);

  const login = useCallback((token, refreshToken) => {
    localStorage.setItem('accessToken', token);
    localStorage.setItem('refreshToken', refreshToken);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    setIsAuthenticated(true);
    setIsLoading(false);
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    delete axios.defaults.headers.common['Authorization'];
    setIsAuthenticated(false);
    setIsLoading(false);
  }, []);

  const authContextValue = useMemo(() => ({
    isAuthenticated,
    isLoading,
    login,
    logout,
    checkAuthStatus
  }), [isAuthenticated, isLoading, login, logout, checkAuthStatus]);

  return (
    <AuthContext.Provider value={authContextValue}>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<PullsList />} />
            <Route path="/registro" element={<RegistroUsuario />} />
            <Route path="/login" element={<LoginUsuario />} />
            <Route path="/pulls" element={<PullsList />} />
            <Route path="/carrito" element={
              <ProtectedRoute>
                <CarritoCompra />
              </ProtectedRoute>
            } />
            <Route path="/mis-pedidos" element={
              <ProtectedRoute>
                <MisPedidos />
              </ProtectedRoute>
            } />
            <Route path="/direccion" element={
              <ProtectedRoute>
                <DireccionEnvio />
              </ProtectedRoute>
            } />
          </Routes>
        </Layout>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;