import React from 'react';
import NavBar from './NavBar';

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <NavBar />
      <main className="flex-grow mt-16 pb-8">
        {children}
      </main>
    </div>
  );
};

export default Layout;