import React, { useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DireccionEnvio from './DireccionEnvio';
import { Minus, Plus } from 'lucide-react';
import { AuthContext } from '../App';

const CarritoCompra = () => {
  const { isAuthenticated, checkAuthStatus } = useContext(AuthContext);
  const [orden, setOrden] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [direccionSeleccionada, setDireccionSeleccionada] = useState('');
  const navigate = useNavigate();

  const cargarOrden = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/current-cart/`);
      setOrden(response.data);
    } catch (error) {
      if (error.response?.status === 401) {
        checkAuthStatus();
      } else if (error.response?.status === 404) {
        await crearNuevaOrden();
      } else {
        setError("Error al cargar el carrito");
      }
    } finally {
      setLoading(false);
    }
  }, [checkAuthStatus]);

  const crearNuevaOrden = useCallback(async () => {
    try {
      const { data: { id: customerId } } = await axios.get(`${process.env.REACT_APP_API_URL}/user/`);
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/sales-orders/`, {
        customer: customerId,
        state: 'draft',
        order_type: 'regular',
        salesorderline_set: []
      });
      setOrden(data);
    } catch (error) {
      setError("Error al crear un nuevo carrito");
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      cargarOrden();
    }
  }, [isAuthenticated, cargarOrden]);

  const actualizarCantidad = useCallback(async (productId, newQuantity) => {
    if (!orden) return;
    try {
      const updatedLines = orden.salesorderline_set.map(line => {
        if (line.product === productId) {
          return {...line, product_qty: Math.max(0, Math.round(newQuantity))};
        }
        return line;
      }).filter(line => line.product_qty > 0);

      const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/sales-orders/${orden.id_salesorder}/`, {
        ...orden,
        salesorderline_set: updatedLines
      });
      setOrden(data);
    } catch (error) {
      console.error('Error al actualizar la orden:', error);
      if (error.response?.status === 401) {
        checkAuthStatus();
      }
    }
  }, [orden, checkAuthStatus]);

  const confirmarOrden = useCallback(async () => {
    if (!orden || !direccionSeleccionada) return;
    try {
      const ordenActualizada = {
        ...orden,
        state: 'sale',
        id_direccion: parseInt(direccionSeleccionada)
      };
      
      await axios.put(`${process.env.REACT_APP_API_URL}/sales-orders/${orden.id_salesorder}/`, ordenActualizada);
      setOrden(null);
      alert('Orden confirmada con éxito');
      navigate('/mis-pedidos');
    } catch (error) {
      console.error('Error al confirmar la orden:', error);
      setError("Error al confirmar la orden. Por favor, intente de nuevo.");
      if (error.response?.status === 401) {
        checkAuthStatus();
      }
    }
  }, [orden, direccionSeleccionada, navigate, checkAuthStatus]);

  if (loading) return <div className="text-center mt-10">Cargando...</div>;
  if (error) return <div className="text-center mt-10 text-red-500">{error}</div>;
  if (!orden || orden.salesorderline_set.length === 0) return <div className="text-center mt-10">No hay productos en el carrito</div>;

  return (
    <div className="carrito-page-container">
      <h2 className="carrito-page-title">Carrito de Compra</h2>
      <div className="carrito-container">
        <div className="carrito-card">
          <h3 className="text-center mb-4">Orden: {orden.name}</h3>
          {orden.salesorderline_set.map((item) => (
            <div key={item.product} className="carrito-item">
              <span className="carrito-item-name">{item.product_name}</span>
              <div className="quantity-controls">
                <button className="quantity-btn" onClick={() => actualizarCantidad(item.product, parseInt(item.product_qty) - 1)}>
                  <Minus size={16} />
                </button>
                <span className="quantity">{parseInt(item.product_qty)}</span>
                <button className="quantity-btn" onClick={() => actualizarCantidad(item.product, parseInt(item.product_qty) + 1)}>
                  <Plus size={16} />
                </button>
              </div>
              <span className="carrito-item-subtotal">S/{(parseFloat(item.price_unit) * parseInt(item.product_qty)).toFixed(2)}</span>
              <button 
                onClick={() => actualizarCantidad(item.product, 0)} 
                className="carrito-item-remove"
              >
                Eliminar
              </button>
            </div>
          ))}
          <p className="carrito-total">Total: S/{parseFloat(orden.amount_total).toFixed(2)}</p>
        </div>
        
        <div className="direccion-card">
          <DireccionEnvio onDireccionSeleccionada={setDireccionSeleccionada} />
        </div>
      </div>

      <div className="carrito-confirm-container">
        <button 
          onClick={confirmarOrden}
          disabled={!direccionSeleccionada}
          className={`carrito-confirm ${!direccionSeleccionada ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          Confirmar Orden
        </button>
      </div>
    </div>
  );
};

export default CarritoCompra;