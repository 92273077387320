import React, { useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../App';

const MisPedidos = () => {
  const [pedidos, setPedidos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
  const { checkAuthStatus } = useContext(AuthContext);

  const estadoColores = {
    draft: 'text-gray-500 border-gray-500',
    sale: 'text-blue-500 border-blue-500',
    done: 'text-green-500 border-green-500',
    cancel: 'text-red-500 border-red-500'
  };

  const cargarPedidos = useCallback(async (page) => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/sales-orders/?page=${page}&state=sale,done,cancel`);
      setPedidos(response.data.results);
      setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page
      setError(null);
    } catch (error) {
      console.error('Error al cargar los pedidos:', error);
      if (error.response?.status === 401) {
        checkAuthStatus();
      } else {
        setError("Error al cargar los pedidos");
      }
    } finally {
      setLoading(false);
    }
  }, [checkAuthStatus]);

  useEffect(() => {
    cargarPedidos(currentPage);
  }, [currentPage, cargarPedidos]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date) 
      ? date.toLocaleString('es-ES', { 
          year: 'numeric', 
          month: 'long', 
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })
      : 'Fecha no válida';
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  if (loading) return <div className="text-center mt-10">Loading...</div>;
  if (error) return <div className="text-center mt-10">{error}</div>;
  if (pedidos.length === 0) return <div className="text-center mt-10">No tienes pedidos confirmados</div>;

  return (
    <div className="pedidos-page-container">
      <h2 className="pedidos-page-title">Mis Pedidos</h2>
      <div className="pedidos-container">
        {pedidos.map((pedido) => (
          <div key={pedido.id_salesorder} className="pedido-card">
            <h3 className="pedido-title">Orden: {pedido.name}</h3>
            <div className={`pedido-estado ${estadoColores[pedido.state]}`}>
              {pedido.state}
            </div>
            <p className="pedido-fecha">Fecha: {formatDate(pedido.date_order)}</p>
            <h4 className="pedido-productos-title">Productos:</h4>
            {pedido.salesorderline_set.map((item, index) => (
              <div key={`${pedido.id_salesorder}-${index}`} className="pedido-item">
                <span className="pedido-item-name">{item.product_name}</span>
                <span className="pedido-item-quantity">{item.product_qty}</span>
                <span className="pedido-item-subtotal">Subtotal: S/{(item.price_unit * item.product_qty).toFixed(2)}</span>
              </div>
            ))}
            <p className="pedido-total">Total: S/{parseFloat(pedido.amount_total).toFixed(2)}</p>
          </div>
        ))}
      </div>
      <div className="pedidos-pagination">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`pedidos-page-button ${currentPage === page ? 'active' : ''}`}
          >
            {page}
          </button>
        ))}
      </div>
    </div>
  );
};

export default MisPedidos;