import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const RegistroUsuario = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    phone: '',
    countrycode: '',
    first_name: '',
    last_name: '',
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/registro/`, formData);
      if (response.data.access) {
        localStorage.setItem('accessToken', response.data.access);
        localStorage.setItem('refreshToken', response.data.refresh);
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
        navigate('/');
      }
    } catch (error) {
      console.error('Error completo:', error);
      setError(error.response?.data?.error || 'Ocurrió un error durante el registro');
    }
  };

  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
  
    if (name === 'countrycode') {
      if (/^\+?\d*$/.test(value) && value.length <= 4) {
        setFormData({ ...formData, countrycode: value });
      }
    } else if (name === 'phone') {
      if (/^\d*$/.test(value) && value.length <= 9) {
        setFormData({ ...formData, phone: value });
      }
    }
  };

  return (
    <div className="auth-page-container">
      <h2 className="auth-page-title">Registro de Usuario</h2>
      <div className="auth-card">
        <form onSubmit={handleSubmit} className="auth-form">
          {error && <p className="auth-error">{error}</p>}
          <div className="auth-input-group">
            <label className="auth-label" htmlFor="username">Username</label>
            <input
              className="auth-input"
              id="username"
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </div>
          <div className="auth-input-group">
            <label className="auth-label" htmlFor="first_name">Nombres</label>
            <input
              className="auth-input"
              id="first_name"
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="auth-input-group">
            <label className="auth-label" htmlFor="last_name">Apellidos</label>
            <input
              className="auth-input"
              id="last_name"
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="auth-input-group">
            <label className="auth-label" htmlFor="email">Correo electrónico</label>
            <input
              className="auth-input"
              id="email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="auth-input-group">
            <label className="auth-label" htmlFor="phone">Celular</label>
            <div className="auth-phone-group">
              <input
                className="auth-input"
                id="countrycode"
                type="text"
                name="countrycode"
                placeholder="+51"
                value={formData.countrycode}
                onChange={handlePhoneChange}
                maxLength="4"
                required
              />
              <input
                className="auth-input"
                id="phone"
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handlePhoneChange}
                maxLength="9"
                required
              />
            </div>
          </div>
          <div className="auth-input-group">
            <label className="auth-label" htmlFor="password">Contraseña</label>
            <input
              className="auth-input"
              id="password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <button className="direccion-button" type="submit">
            Registrarse
          </button>
        </form>
      </div>
    </div>
  );
};

export default RegistroUsuario;