import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const DireccionEnvio = ({ onDireccionSeleccionada }) => {
  const [direcciones, setDirecciones] = useState([]);
  const [direccionSeleccionada, setDireccionSeleccionada] = useState('');
  const [mostrarNuevaDireccion, setMostrarNuevaDireccion] = useState(false);
  const [nuevaDireccion, setNuevaDireccion] = useState({
    calle: '',
    numero: '',
    referencia: '',
    departamento: '',
    provincia: '',
    distrito: ''
  });
  const [ubigeos, setUbigeos] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [distritos, setDistritos] = useState([]);

  const cargarDatos = useCallback(async () => {
    try {
      const [direccionesRes, ubigeosRes] = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_URL}/direcciones/`),
        axios.get(`${process.env.REACT_APP_API_URL}/ubigeos/`)
      ]);
      setDirecciones(direccionesRes.data);
      setUbigeos(ubigeosRes.data);
      const uniqueDepartamentos = [...new Set(ubigeosRes.data.map(u => u.departamento))];
      setDepartamentos(uniqueDepartamentos);
    } catch (error) {
      console.error('Error al cargar datos:', error);
    }
  }, []);

  useEffect(() => {
    cargarDatos();
  }, [cargarDatos]);

  const handleNuevaDireccionChange = useCallback((e) => {
    const { name, value } = e.target;
    setNuevaDireccion(prev => ({ ...prev, [name]: value }));

    if (name === 'departamento') {
      const provinciasDelDepartamento = [...new Set(ubigeos.filter(u => u.departamento === value).map(u => u.provincia))];
      setProvincias(provinciasDelDepartamento);
      setDistritos([]);
      setNuevaDireccion(prev => ({ ...prev, provincia: '', distrito: '' }));
    } else if (name === 'provincia') {
      const distritosDeProvinciaYDepartamento = [...new Set(ubigeos.filter(u => 
        u.departamento === nuevaDireccion.departamento && u.provincia === value
      ).map(u => u.distrito))];
      setDistritos(distritosDeProvinciaYDepartamento);
      setNuevaDireccion(prev => ({ ...prev, distrito: '' }));
    }
  }, [nuevaDireccion.departamento, ubigeos]);

const agregarNuevaDireccion = useCallback(async () => {
     try {
       const { data: { id: userId } } = await axios.get(`${process.env.REACT_APP_API_URL}/user/`);
       const ubigeoSeleccionado = ubigeos.find(u => 
         u.departamento === nuevaDireccion.departamento && 
         u.provincia === nuevaDireccion.provincia && 
         u.distrito === nuevaDireccion.distrito
       );

       if (!ubigeoSeleccionado || !ubigeoSeleccionado.id_ubigeo) {
         throw new Error('Ubigeo no encontrado o inválido');
       }

       const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/direcciones/`, {
         usuario: userId,
         calle: nuevaDireccion.calle,
         numero: nuevaDireccion.numero,
         referencia: nuevaDireccion.referencia,
         id_ubigeo: ubigeoSeleccionado.id_ubigeo
       });

       setDirecciones(prev => [...prev, data]);
       setDireccionSeleccionada(data.id_direccion);
       onDireccionSeleccionada(data.id_direccion);
       setMostrarNuevaDireccion(false);
     } catch (error) {
       console.error('Error al agregar nueva dirección:', error);
       // You might want to show an error message to the user here
     }
   }, [nuevaDireccion, ubigeos, onDireccionSeleccionada]);

   return (
    <div className="direccion-container">
      <h4 className="direccion-title">Dirección de Envío</h4>
      <select
        value={direccionSeleccionada}
        onChange={(e) => {
          setDireccionSeleccionada(e.target.value);
          onDireccionSeleccionada(e.target.value);
        }}
        className="direccion-select"
      >
        <option value="">Seleccione una dirección</option>
        {direcciones.map((dir) => (
          <option key={dir.id_direccion} value={dir.id_direccion}>
            {`${dir.calle} ${dir.numero}, ${dir.id_ubigeo.distrito}`}
          </option>
        ))}
      </select>
      <button 
        onClick={() => setMostrarNuevaDireccion(!mostrarNuevaDireccion)}
        className="direccion-button"
      >
        {mostrarNuevaDireccion ? 'Cancelar' : 'Agregar Nueva Dirección'}
      </button>
      {mostrarNuevaDireccion && (
        <div className="direccion-form">
          <input
            type="text"
            name="calle"
            placeholder="Calle"
            value={nuevaDireccion.calle}
            onChange={handleNuevaDireccionChange}
            className="direccion-input"
          />
          <input
            type="text"
            name="numero"
            placeholder="Número"
            value={nuevaDireccion.numero}
            onChange={handleNuevaDireccionChange}
            className="direccion-input"
          />
          <input
            type="text"
            name="referencia"
            placeholder="Referencia"
            value={nuevaDireccion.referencia}
            onChange={handleNuevaDireccionChange}
            className="direccion-input"
          />
          <select
            name="departamento"
            value={nuevaDireccion.departamento}
            onChange={handleNuevaDireccionChange}
            className="direccion-input"
          >
            <option value="">Seleccione Departamento</option>
            {departamentos.map(dep => (
              <option key={dep} value={dep}>{dep}</option>
            ))}
          </select>
          <select
            name="provincia"
            value={nuevaDireccion.provincia}
            onChange={handleNuevaDireccionChange}
            className="direccion-input"
          >
            <option value="">Seleccione Provincia</option>
            {provincias.map(prov => (
              <option key={prov} value={prov}>{prov}</option>
            ))}
          </select>
          <select
            name="distrito"
            value={nuevaDireccion.distrito}
            onChange={handleNuevaDireccionChange}
            className="direccion-input"
          >
            <option value="">Seleccione Distrito</option>
            {distritos.map(dist => (
              <option key={dist} value={dist}>{dist}</option>
            ))}
          </select>
          <button 
            onClick={agregarNuevaDireccion}
            className="direccion-button"
          >
            Guardar Nueva Dirección
          </button>
        </div>
      )}
    </div>
  );
};

export default DireccionEnvio;